import imgBull from '../img/bull-web.jpg';
import imgVoid from '../img/void.jpg';
import imgHorsemen from '../img/horsemen.resized.jpg';
import imgSelfPortrait from '../img/self-portrait-web.jpg';
import imgTooth from '../img/tooth-web.jpg';
import imgHead from '../img/bbh-web.jpg';
import imgPeople from '../img/people-web.jpg';
import imgGaslight from '../img/gaslight-web.jpg'


const inventory = {
    items: [
        {
            id: 2,
            title: "Horsemen",
            alt: "Painting of human figures",
            medium: "38 1/2\" x 42\" Acrylic paint and charcoal on paper.",
            description: "Three human figures in conversation, a skeleton off to the right side, and an onlooking horse.",
            image: imgHorsemen,
        },
        {
            id: 3,
            title: "Bull",
            alt: "Abstract painting with flowing shapes",
            medium: "59\" x 35\" Acrylic paint, chalk, and charcoal on canvas.",
            description: "An abstract painting with flowing shapes and colors.",
            image: imgBull,
        },
        {
            id: 4,
            title: "Early Self-Portrait",
            alt: "Abstract painting with flowing shapes",
            medium: "48\" x 48\" Acrylic paint on vinyl.",
            description: "A portrait painted with warm pastel colors.",
            image: imgSelfPortrait,
        },
        {
            id: 5,
            title: "Tooth",
            alt: "Abstract painting with flowing shapes",
            medium: "60\" x 38\" Acrylic paint on canvas.",
            description: "An abstract painting of large flowing shapes in warm colors.",
            image: imgTooth,
        },
        {
            id: 6,
            title: "Head",
            alt: "Abstract painting with flowing shapes",
            medium: "43\" x 42\" Acrylic paint on paper.",
            description: "A painting of a head from the neck up in a blocky style with high contrast colors, framed by organic flowing shapes.",
            image: imgHead,
        },
        {
            id: 7,
            title: "People",
            alt: "Abstract painting with flowing shapes",
            medium: "60\" x 38\" Acrylic paint on canvas.",
            description: "Three human figures in conversation in bright colors with a loose light colored background.",
            image: imgPeople,
        },
        {
            id: 8,
            title: "Gaslight",
            alt: "Painting featuring faces",
            medium: "48\" x 34\" Acrylic paint on paper.",
            description: "Three faces, two slightly out of frame and one in full view with an open mouth, in bright contrasting colors.",
            image: imgGaslight,
        },
        {
            id: 1,
            title: "Void",
            alt: "Painting with flowing shapes",
            medium: "36\" x 24\" Acrylic paint on canvas.",
            description: "An abstract painting with flowing shapes and color, with dark shapes cut out.",
            image: imgVoid,
        },
    ]

}

export default { inventory }