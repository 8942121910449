import React, { Component } from 'react';


export default class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render () {
    return (
      <div className="animate__animated animate__fadeIn center loading-container">
          <h1>LOADING</h1>
      </div>
    )
      
  }

}