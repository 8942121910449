import React, { useEffect, useReducer } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Loading from './Loading';
import { Link } from 'react-router-dom';
import Policy from './Policy';

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return {
        ...state,
        ...action.payload
      };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const PrintShop = () => {
  const [state, dispatch] = useReducer(reducer, {
    checkout_item: null,
    loading: false,
    error: null,
    stripe: null,
    products: null,
  });

  useEffect(() => {
    try {
    async function fetchConfig() {
      const { publicKey, currency, products } = await fetch(
        '/api/config'
      ).then((res) => res.json());
      dispatch({
        type: 'useEffectUpdate',
        payload: { products, currency, stripe: await loadStripe(publicKey) },
      });
    }
    fetchConfig();
    }
    catch {
      console.log("Error getting config");
    }
  }, []);

  function populateShop(products){
    const shopItems = products.map((item, key) => (
              <Link className="animate__animated animate__fadeIn" key={key} to={"/print/" + item.name.split(' ').join('-')}>
        <div className="four columns shop-item center">
            <h2>{item.name.split(' ')[0]}</h2>
              <img
                decoding="sync"
                title={item.name}
                alt={item.alt}
                src={item.image[0]}
              />
              <h3>{!item.prices? "" : item.prices.length + " print sizes available"}</h3>
          <div className="error">{state.error?.message}</div>
        </div>
                </Link>
    ));
    return shopItems;
  }


  return (
    <div className="container center shop-container">

          <br/>
          <h2 className="animate__animated animate__fadeInUp"> Prints of my paintings</h2>
          <h3 className="animate__animated animate__fadeInDown">
          All prints are made-to-order in-house by me
          </h3>
          <hr></hr>
          <br/>

      <div className="row">
      {state.loading || !state.products
                        ? <Loading/ >
                        : populateShop(state.products)}
      </div>
      <Policy />
    </div>

  );
};

export default PrintShop;
