import React, { Suspense, Component } from 'react';
import Loading from './Loading';
const GalleryImage = React.lazy( () => import ('./GalleryImage') );

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
      
    render () {    

    return (

      <Suspense fallback={<Loading />}>
        <div className="container center">
          <br/>
          <h2 className="animate__animated animate__fadeInUp">A selection of my work in painting and other physical media</h2>
          <hr></hr>
          <br/>
          <div className="gallery-contaier">
          <GalleryImage />
          </div>
        </div>
      </Suspense>
    )
      
  }

}