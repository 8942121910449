import { React, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import Nav from './components/Nav';
import PrintShop from './components/PrintShop';
import Success from './components/Success';
import Canceled from './components/Canceled';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import About from './components/About';
import ImagePage from './components/ImagePage';
import ShopItemPage from './components/ShopItemPage';
import Cart from './components/Cart';

import './css/normalize.css';
import './css/skeleton.css';
import './css/global.css';

import inventory from "./components/Inventory.js";

function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [shopItems, setShopItems] = useState();
  const [shopRoutes, setShopRoutes] = useState(['loading']);
 
  ReactGA.initialize('G-ERHMGLD10T', {
    debug: false
  });

  useEffect(() => {
    fetch(`/api/shop-items`, {})
      .then((res) => res.json())
      .then((response) => {
        if (isLoading === true) {
          setShopItems(response);
          if (shopItems) {setShopRoutes(createShopRoutes(shopItems));}
        };
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [isLoading, shopItems, shopRoutes]);


  function createShopRoutes(items) {
    return ( 
      items.map((item, key) => (
        <Route key={key} path={"/print/" + item.name.split(' ').join('-')}>
                    {<ShopItemPage imageItem={item} />}
                    <Footer />
                  </Route>
      ))
    )
  }

  function createImageRoutes(inv) {
  
  return (
    inv.inventory.items.map((item, key) => (
      <Route key={key} path={"/" + item.title + ".html"}>
                  <Nav />
                  {<ImagePage imageItem={item} />}
                  <Footer />
                </Route>
    ))
  )
}

  return (
    <Router>
      <Switch>
        {shopRoutes[0] !== 'loading' ? shopRoutes : ''}
        {createImageRoutes(inventory)}
        <Route path="/success.html">
          <Nav />
          <Success />
          <Footer />
        </Route>
        <Route path="/canceled.html">
          <Nav />
          <Canceled />
          <Footer />
        </Route>
        <Route path="/prints.html">
          <Nav />
          <PrintShop />
          <Footer />
        </Route>
        <Route path="/gallery.html">
          <Nav />
          <Gallery />
          <Footer />
        </Route>
        <Route path="/about.html">
          <Nav />
          <About />
          <Footer />
        </Route>
        <Route path="/cart.html">
          <Cart />
          <Footer />
        </Route>
        <Route path="/">
          <Nav />
          <Gallery />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
