import React from 'react';
import imgNina from '../img/about-pic.jpg';
import ReactGA from 'react-ga';

function About() {
    ReactGA.pageview('/about.html');
    return (
        <div className="container about-container">
            <br/>
          <h2 className="animate__animated animate__fadeInUp center">Nina Paints</h2>
          <hr></hr>
          <br/>
            <div className="container row animate__animated animate__fadeIn">
                <div className="columns six">
            <p>
            I was born and raised in the Pacific Northwest. I fell in love with painting after taking classes at my local community college. It took a while to like the things I made, but it was a skill that just felt right.
            </p>
            <p>
            My materials of choice are unstretched canvas, chalk, water, and acrylic paint. As a person I tend to be passionate and feeling about the world around me which helps inform my creativity. When I paint I hope to explore feelings, relationships, and growth.
            </p>
            <p>
            When I'm not painting you can find me admiring my houseplants, playing Tetris Attack, or hiking with my partner and our dog.
            </p>
                </div>
            <img alt="A picture of Nina" src={imgNina} className="columns six">

            </img>
            </div>
        </div>
    )
}

export default About;