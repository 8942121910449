import React, { Component } from "react";
import { loadStripe } from '@stripe/stripe-js';
import imgCartPlus from '../img/cart-plus.png';
import imgCartMinus from '../img/cart-minus.png';
import Nav from './Nav';

export default class Cart extends Component {
  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.state = {
      cart: [],
      total: 0,
      quants: 0,
      cartElements: [],
      loading: true,
      stripe: null,
      currency: null,
    }
  }

  getTotal(cart) {
    let total = 0;
    if(cart){
    let amounts = cart.map(i => {
      return i.price * i.quantity
    });
    let quants = cart.map(i => {
      return i.quantity
    });
    this.setState({quants: quants});
    total = amounts.reduce(function(acc, val) { return acc + val; }, 0);
    }
    return total
  }

  refresh () {
    this.setState({loading: true});
    let localCart = sessionStorage.getItem('cart');
    localCart = JSON.parse(localCart);
    this.setState({cart: localCart});
    this.totals = this.getTotal(localCart);
    this.setState({total: this.totals});
    this.setState({loading: false});
  }

  updateCart (itemID, amount) {
    this.setState({loading: true});
    let cartCopy = [...this.state.cart];
    let existingItem = cartCopy.find(item => item.id === itemID);
    if (!existingItem) return;
    existingItem.quantity += amount;
    if (existingItem.quantity <= 0) {
      cartCopy = cartCopy.filter(item => item.id !== itemID);
    }
    this.setState({cart: cartCopy});
    const cartString = JSON.stringify(cartCopy);
    sessionStorage.setItem('cart', cartString);
    if (sessionStorage.getItem('cart') === "[]") {
      this.clearCart();
    }
    this.refresh();
  }

  removeItem (itemID) {
    let cartCopy = [...this.state.cart];
    cartCopy = cartCopy.filter(item => item.id !== itemID);
    this.setState({cart: cartCopy});
    const cartString = JSON.stringify(cartCopy);
    sessionStorage.setItem('cart', cartString);
    this.refresh();
  }

  clearCart() {
    sessionStorage.clear();
    this.setState({quants: 0});
    this.setState({cart: []});
    this.refresh();
  }

  componentDidMount() {
    this.refresh();
    this.setState({loading: false});
    this.fetchConfig();
  }

  fetchConfig = async () => {
    this.setState({loading: true});
    const { publicKey, currency } = await fetch('/api/config').then((res) => res.json());
    this.setState({currency: currency, stripe: await loadStripe(publicKey), loading: false});
  }

  fetchCheckoutSession = async (checkout_items) => {
    return fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        checkout_items,
      }),
    }).then((res) => res.json());
  };

  handleClick = async (event) => {
    this.setState({loading: true});
    const { sessionId } = await this.fetchCheckoutSession(this.packageCart());
    const { error } = await this.state.stripe.redirectToCheckout({
      sessionId,
    });
    if (error) {
      console.log(error);
      this.setState({loading: false});
    }
  };

  packageCart() {
    if (this.state.cart){
      const checkoutItems = this.state.cart.map(i => {
        return {price: i.id, quantity: i.quantity}
      })
      return checkoutItems
    }
  }

  
  render() {
    if (this.state.cart) {
      this.shopItems = this.state.cart.map((i, key) => (
        <div className="container">
          <div className="cart-item-text">
              {i.name} ({i.size}) - Quantity: {i.quantity}
          </div>
          <br />
              <div className="cart-mod-icon-container">
            <button className="cart-mod-button" onClick={() => this.updateCart(i.id, -1)}>
              <img alt="reduce amount" className="cart-mod-icon" src={imgCartMinus}></img>
            </button>
            <button className="cart-mod-button" onClick={() => this.updateCart(i.id, 1)}>
              <img alt="increase amount" className="cart-mod-icon" src={imgCartPlus}></img>
            </button>
              </div>
              <br />
        </div>
      ));
    } else {
      this.shopItems = <div>Cart Empty</div>
    }

    return (
      <>
      <Nav key={this.state.quants} count={this.state.quants}/>
      <div className="container cart-container center">
        <div className="container cart-top">
            <h2 className="animate__animated animate__fadeInUp">Cart</h2>
            <hr></hr>
            <div className="animate__animated animate__fadeIn">
              {this.shopItems}
              <p className="center">
              </p>
                <h2 className="">
                  <br/><br /><br />
              TOTAL = ${this.totals}
              <br /><br />
                </h2>
            </div>
            <br/>
            <div>

            </div>
        </div>
        <div className="cart-bottom">
            <div className="container row">
                <button className="cart-button columns six" disabled={!this.state.stripe || this.state.loading || this.state.quants === 0} onClick={() => this.clearCart()}>Clear Cart</button>
                <button className="cart-button columns six" disabled={!this.state.stripe || this.state.loading || this.state.quants === 0} onClick={() => this.handleClick()}>{!this.state.stripe || this.state.loading? "Loading..." : "Checkout"}</button>
            </div>
        </div>
      </div>
      </>
    )


  }
}
