import React from 'react';

function Policy() {
    return (
        <div className="container policy-container animate__animated animate__fadeIn">
            <div className="container">
                <div className="row">
                    <div className="columns six left">
                        <p>
                        Items ship 3-5 days once payment is processed. Returns and refunds available within 30 days of purchase. Currently shipping only within the United States using USPS.
                        </p>
                    </div>
                    <div className="columns six left">
                        <p>
                        All prints are signed on the back unless otherwise requested. All items are made and packaged in house.
                        </p>
                        <p>
                            Free shipping on all prints.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Policy;