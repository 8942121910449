import React, { Component } from "react";
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

export default class ImagePage extends Component {


  render() {
    const { title, image, alt, medium, description } = this.props.imageItem;
    ReactGA.pageview('/' + title + '.html');
    return (
      <div className="container">
      <br/>
          <h2 className="center animate__animated animate__fadeInUp"> {title}</h2>
          <hr></hr>
          <br/>
          <div className="animate__animated animate__fadeIn">
            <div className="container center image-page-container">
            <Link to={image} target="_blank">
            <img decoding="sync" alt={alt} className="image-page-image" src={image}></img>
            </Link>
            <h3 className="left image-medium">{medium}</h3>
            <p className="left">{description}</p>
            </div>
        </div>
      </div>
    )


  }
}
