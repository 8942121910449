import React from 'react';
import { SocialIcon } from 'react-social-icons';

function Footer() {
    return (
        <div className="container footer-container">
            <hr/>
            <div className="container">
                <div className="row footer-items">
                    <div className="columns six">
                        <p>
                            Feel free to contact me with questions through my social media.
                        </p>
                    </div>
                    <div className="columns six center">
                            <SocialIcon className="social" url="https://www.instagram.com/painthoard/" bgColor="#296e6c00" fgColor="#296e6c"/>
                            <SocialIcon className ="social" url="https://twitter.com/painthoard" bgColor="#296e6c00" fgColor="#296e6c"/>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <p className="right">Copyright {new Date().getFullYear() + " Nina Paints - "} Website by <a href="https://jdamakes.com/">jdamakes</a></p>
        </div>
    )
}

export default Footer;