import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Loading from './Loading';

const Success = () => {
  const [session, setSession] = useState({});
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');
  sessionStorage.clear();

  
  useEffect(() => {
    async function fetchSession() {

      setSession(
        await fetch('/api/checkout-session?sessionId=' + sessionId).then((res) =>
          res.json()
        ).catch(error => {console.log(error)})
      );
    }
    fetchSession();
  }, [sessionId]);
  console.log(session);

  const validSuccess = (
    <div className="container">
      <div className="animate__animated animate__fadeIn center">
        <h1>Your payment succeeded</h1>
        <h2>Thank You!</h2>

        <p className="m-top m-bottom">
          Your item will be shipped in the next 3 to 5 days by USPS. You will recieve a tracking number via email {session.customer_details? 'to ' + JSON.stringify(session.customer_details.email, null, 2) : ''} when the item is shipped.
        </p>

        <h2 className="nav-menu-list m-top">
                <Link to="/">Back to Homepage</Link>
            </h2>
      </div>
    </div>
  )

  const invalidSuccess = (
    <div className="container">
    <div className="animate__animated animate__fadeIn center">
      <p className="m-top m-bottom">
        Something went wrong, or you refreshed the page. Please check your email if you placed an order.
      </p>
      <h2 className="nav-menu-list m-top">
              <Link to="/">Back to Homepage</Link>
          </h2>
    </div>
  </div>
  )

  return (
    session.customer_details ? validSuccess : invalidSuccess
  );
};

export default Success;
