import { React, Component, useEffect} from 'react';
import logo from '../img/logo.png';
import { Link } from "react-router-dom";
import cartImg from '../img/cart.png';

export default class Nav extends Component {
    constructor(props) {
      super(props);
      this.state = {
          count: this.props.count,
      }
    }

    getCount() {
        let localCart = sessionStorage.getItem('cart');
        if (localCart){
            let count = 0;
            localCart = JSON.parse(localCart);
            localCart.map(i => (count += i.quantity));
            this.setState({count: count});
            this.setState({cart: localCart});
        }
    }
    componentDidMount() {
        this.getCount();
    }
    
    render() {
        return(
            <div className='container nav-menu-container'>
                <div className='nav-menu'>
                    <ul className='nav-menu-list row center'>
                        <li className="nav-logo-container columns four">
                        <Link to="/" className="nav-logo-link"><img className='nav-logo' src={logo} alt="Logo for Nina Paints"></img></Link>
                        </li>
                        <h2 className="nav-links">
                        <li className='columns two'>
                            <Link to="/prints.html">Prints</Link>
                        </li>
                        <li className='columns two'>
                            <Link to="/gallery.html">Gallery</Link>
                        </li>
                        <li className='columns two'>
                            <Link to="/about.html">About</Link>
                        </li>
                        <li className="columns two cart-icon-container">
                            <Link to="/cart.html">
                            <div className="cart-icon-container">
                            {this.state.count >= 100? <img className="cart-icon" style={{marginRight: '2rem'}} src={cartImg} alt="Shopping Cart"></img> : <img className="cart-icon" src={cartImg} alt="Shopping Cart"></img>}
                            <p className="badge animate__animated animate__fadeIn">{this.state.count === 0? '' : this.state.count}</p>
                            </div>
                            </Link>
                        </li>
                        </h2>
                    </ul>
                </div>
            </div>
        )
    }

}
