import React from 'react';
import { Link } from 'react-router-dom';

function Canceled() {
    return (
        <div className="container about-container">
            <br/>
          <h1 className="animate__animated animate__fadeIn center">Payment Canceled</h1>
          <br/>
            <div className="container center row">
            <br/>
            <h2 className="nav-menu-list">
                <Link to="/">Back to Homepage</Link>
            </h2>
            </div>
            <br/>
        </div>
    )
}

export default Canceled;