import React, { useEffect, useReducer, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Loading from './Loading';
import Policy from './Policy';
import Nav from './Nav';
import imgCart from '../img/cart.png';

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return {
        ...state,
        ...action.payload
      };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const ShopItemPage = (props) => {
  const [count, setCount] = useState();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState([true]);
  let localCart = sessionStorage.getItem('cart');
  const { name, image, description, prices } = props.imageItem;
  const [state, dispatch] = useReducer(reducer, {
    checkout_item: null,
    loading: false,
    error: null,
    stripe: null,
    products: null,
  });

  const addToCart = (item) => {
    let cartCopy = [...cart];
    let { id } = item;
    let existingItem = cartCopy.find(i => id === i.id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      cartCopy.push(item);
    }
    let stringCart = JSON.stringify(cartCopy);
    sessionStorage.setItem('cart', stringCart);
    setCart(cartCopy);
  }

  useEffect(() => {
    async function fetchConfig() {
      const { publicKey, currency, products } = await fetch(
        '/api/config'
      ).then((res) => res.json());
      dispatch({
        type: 'useEffectUpdate',
        payload: { products, currency, stripe: await loadStripe(publicKey) },
      });
    }
    fetchConfig();
  }, []);
  
  useEffect(() => {
    localCart = JSON.parse(localCart);
    if (localCart) {
      setCart(localCart);
    };
    setLoading(false);
  }, []);

  function handleClick(event) {
    setLoading(true);
    let item = event.currentTarget.id;
    let price = event.currentTarget.value;
    let size = event.currentTarget.getAttribute("datasize");
    let name = event.currentTarget.getAttribute("dataname");
    addToCart({id: item, name: name, size: size, price: price, quantity: 1});
    getCount();
    confirmButton(event);
    setLoading(false);
  }

  function confirmButton(event) {
    if (!event.currentTarget.className.includes("animate2")) {
      event.currentTarget.className = "add-to-cart-button animate2";
    } else if (event.currentTarget.className.includes("animate2")) {
      event.currentTarget.className = "add-to-cart-button animate";
    }
  }

  function getCount() {
    if (cart){
        let quants = 0;
        cart.map(i => (quants += i.quantity));
        setCount(quants);
    }
}

  const printElement = 
          <div className="container center print-page-container">
            <br/>
          <h2 className="animate__animated animate__fadeInUp"> {name ? name : ''}</h2>
          <hr></hr>
          <br/>
            <div className="row">
            <div className="columns nine">
                <img decoding="sync" alt={name? name : 'image of print'} className="print-page-image animate__animated animate__fadeIn" src={image ? image : ''}></img>
            <p>{description ? description : ''}</p>
            </div>
            <div className="columns three print-item-prices">
              <div className="row center price-button-container">
                {!prices[0]? '' : prices.map((i, key) => (
                  <div key={key} className="columns twelve left price-button animate__animated animate__fadeIn">
                    <div className="price-button-inner center">
                      <h2 className="left">
                        {i.description}
                      </h2>
                      <p className="left">
                        ${i.unit_amount/100}
                      </p>
                  <button disabled={loading} dataname={name} datasize={i.description} value={i.unit_amount/100} id={i.price_object} role="link" onClick={handleClick} disabled={!state.stripe || state.loading}><h2>Add to Cart</h2></button>
                    </div>
                  </div>
                  ))}

              </div>
            </div>
            <div className="error">{state.error?.message}</div>
            </div>
          </div>;
  return (
    <>
    <Nav key={count} count={count}/>
    <div className="container shop-container">
      <div className="row">
      {state.loading || !state.products
                        ? <Loading/ >
                        : printElement}
      </div>     
      <Policy />
    </div>
    </>

  );
};

export default ShopItemPage;
